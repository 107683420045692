.highlight .hll { background-color: #515151 }
/*.highlight  { background: #2d2d2d; color: #f2f0ec }*/
.highlight  { background: #1A1F35; color: #f2f0ec }
.highlight .c { color: #A3CACD } /* Comment */
.highlight .err { color: #f2777a } /* Error */
.highlight .k { color: #cc99cc } /* Keyword */
.highlight .l { color: #f99157 } /* Literal */
.highlight .n { color: #f2f0ec } /* Name */
.highlight .o { color: #66cccc } /* Operator */
.highlight .p { color: #f2f0ec } /* Punctuation */
.highlight .ch { color: #A3CACD } /* Comment.Hashbang */
.highlight .cm { color: #A3CACD } /* Comment.Multiline */
.highlight .cp { color: #A3CACD } /* Comment.Preproc */
.highlight .cpf { color: #A3CACD } /* Comment.PreprocFile */
.highlight .c1 { color: #A3CACD } /* Comment.Single */
.highlight .cs { color: #A3CACD } /* Comment.Special */
.highlight .gd { color: #f2777a } /* Generic.Deleted */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gh { color: #f2f0ec; font-weight: bold } /* Generic.Heading */
.highlight .gi { color: #99cc99 } /* Generic.Inserted */
.highlight .gp { color: #A3CACD; font-weight: bold } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #66cccc; font-weight: bold } /* Generic.Subheading */
.highlight .kc { color: #cc99cc } /* Keyword.Constant */
.highlight .kd { color: #cc99cc } /* Keyword.Declaration */
.highlight .kn { color: #66cccc } /* Keyword.Namespace */
.highlight .kp { color: #cc99cc } /* Keyword.Pseudo */
.highlight .kr { color: #cc99cc } /* Keyword.Reserved */
.highlight .kt { color: #ffcc66 } /* Keyword.Type */
.highlight .ld { color: #99cc99 } /* Literal.Date */
.highlight .m { color: #f99157 } /* Literal.Number */
.highlight .s { color: #99cc99 } /* Literal.String */
.highlight .na { color: #6699cc } /* Name.Attribute */
.highlight .nb { color: #f2f0ec } /* Name.Builtin */
.highlight .nc { color: #ffcc66 } /* Name.Class */
.highlight .no { color: #f2777a } /* Name.Constant */
.highlight .nd { color: #66cccc } /* Name.Decorator */
.highlight .ni { color: #f2f0ec } /* Name.Entity */
.highlight .ne { color: #f2777a } /* Name.Exception */
.highlight .nf { color: #6699cc } /* Name.Function */
.highlight .nl { color: #f2f0ec } /* Name.Label */
.highlight .nn { color: #ffcc66 } /* Name.Namespace */
.highlight .nx { color: #6699cc } /* Name.Other */
.highlight .py { color: #f2f0ec } /* Name.Property */
.highlight .nt { color: #66cccc } /* Name.Tag */
.highlight .nv { color: #f2777a } /* Name.Variable */
.highlight .ow { color: #66cccc } /* Operator.Word */
.highlight .w { color: #f2f0ec } /* Text.Whitespace */
.highlight .mb { color: #f99157 } /* Literal.Number.Bin */
.highlight .mf { color: #f99157 } /* Literal.Number.Float */
.highlight .mh { color: #f99157 } /* Literal.Number.Hex */
.highlight .mi { color: #f99157 } /* Literal.Number.Integer */
.highlight .mo { color: #f99157 } /* Literal.Number.Oct */
.highlight .sa { color: #99cc99 } /* Literal.String.Affix */
.highlight .sb { color: #99cc99 } /* Literal.String.Backtick */
.highlight .sc { color: #f2f0ec } /* Literal.String.Char */
.highlight .dl { color: #99cc99 } /* Literal.String.Delimiter */
.highlight .sd { color: #A3CACD } /* Literal.String.Doc */
.highlight .s2 { color: #99cc99 } /* Literal.String.Double */
.highlight .se { color: #f99157 } /* Literal.String.Escape */
.highlight .sh { color: #99cc99 } /* Literal.String.Heredoc */
.highlight .si { color: #f99157 } /* Literal.String.Interpol */
.highlight .sx { color: #99cc99 } /* Literal.String.Other */
.highlight .sr { color: #99cc99 } /* Literal.String.Regex */
.highlight .s1 { color: #99cc99 } /* Literal.String.Single */
.highlight .ss { color: #99cc99 } /* Literal.String.Symbol */
.highlight .bp { color: #f2f0ec } /* Name.Builtin.Pseudo */
.highlight .fm { color: #6699cc } /* Name.Function.Magic */
.highlight .vc { color: #f2777a } /* Name.Variable.Class */
.highlight .vg { color: #f2777a } /* Name.Variable.Global */
.highlight .vi { color: #f2777a } /* Name.Variable.Instance */
.highlight .vm { color: #f2777a } /* Name.Variable.Magic */
.highlight .il { color: #f99157 } /* Literal.Number.Integer.Long */
