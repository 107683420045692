@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

html { height: 100%; }

body {
  font-family: "Epilogue", -apple-system, "avenir next", avenir, roboto, noto, ubuntu, "helvetica neue", helvetica, sans-serif;
  font-size: 0.9rem;
  line-height: 2;
  margin: 0;
  min-height: 100%;
}
pre, code {
  font-family: "Roboto Mono", "Courier New", monospace;
  font-size: 0.9rem;
}

h2, h3, h4, h5 {
  margin-top: 1.5rem;
  margin-bottom: -0.5rem;
}
//h1, h2, h3, h4, h5, h6 { font-family: 'Space Grotesk', 'Space Mono', monospace; }

hr { margin: 1em 0; }

hr.page-break {
  text-align: center;
  border: 0;

  &:before { content: '-----' }
  &:after { content: attr(data-content) '-----' }
}

p { margin: 1em 0; }

li { margin: 0.4em 0; }

.w {
  max-width: 720px;
  margin: 0 auto;
  padding: 4em 2em;
}

table, th, td {
  width: 100%;
  border: thin solid black;
  border-collapse: collapse;
  padding: 0.4em;
}

div.highlighter-rouge pre code, pre code.highlighter-rouge {
  display: block;
  overflow-x: auto;
  padding: 1em;
}

div.highlighter-rouge {
  //width: 90%;
  //margin-left: 5%;
}

blockquote {
  font-style: italic;
  border: thin solid black;
  padding: 1em;

  p { margin: 0; }
}

img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
html {
    --bg: #FFF;
    //--bg-secondary: #F8F9FA;
    //--bg-secondary: #f8f8f8; // lighter grey
    --bg-secondary: #F3F4F6; // slightly darker grey
    --bg-secondary: #f8f8f8; // lighter grey
    //--headings: #000;
    --headings: #374151;
    //--text: #333;
    --text: #374151;
    --links: #2563EB;
    //--highlight: #FFD19B;
    --highlight: #FFECB2; // light yellow
    --code-text: #9D174D;
    --secondary-text: #9CA3AF;
}
// -------------- THEME SWITCHER -------------- //
@mixin dark-appearance {
  html, body  {
      //--bg: #232940;
      --bg: #263056;
      --bg-secondary: #1A1F35;
      --headings: #74c0fc;
      //--text: #A3CACD;
      //--text: #9CA3AF; //darker grey
      --text: #D1D5DB; // lighter grey
      --links: #91A7FF;
      --highlight: #41C7C7;
      --code-text: #A7F3D0;
      --secondary-text: #9CA3AF;
      //--bg: #1f242A; // original dark
      //--bg-secondary: #323945; // original secondary
      //--text: #adb5bd; // original text
      //--highlight: #1D7E7E; // original olive
      //--bg: #172a45; // light navy blue
      //--bg: #0a192f; // dark navy blue
      //--bg-secondary: #303C55; // lightest navy blue
      //--text: #e6f1ff; // white-ish
      //--text: #8892b0; // slate
  };
}
html[data-theme="dark"] { @include dark-appearance; }

@media (prefers-color-scheme: dark) {
  body[data-theme="auto"] { @include dark-appearance; }
}
// -------------------------------------------- //

html, body {
    background-color: var(--bg);
    color: var(--text);
}
h1, h2, h3, h4, h5, h6 {
    color: var(--headings);
}
p, strong, b, em, small, li, hr, table, figcaption {
    color: var(--text);
}
.highlight, pre code, blockquote {
  border-radius: 0.5em;
}
blockquote {
  background-color: var(--bg-secondary);
  border: 1px var(--border) solid;
}
a {
  color: var(--links);
  text-decoration: none;
}
*:target { background-color: var(--bg-secondary); }

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
    transition: all 250ms !important;
    transition-delay: 0 !important;
}

.theme-toggle {
  color: var(--text);
  background-color: transparent;
  padding: 4px;
  cursor: pointer;
  margin: 1em;
  position: fixed;
  right: 0;
  top: 0;
  border: 2px transparent solid;
  outline: none;
}

.theme-toggle:hover {
  color: var(--links);
  outline: none;
}
.theme-toggle:focus {
  outline: none;
}
.dashed {
  border-top: 1px var(--text) dashed;
  margin: 0.5em 0;
}
mark {
  padding: 0.4em;
  background-color: var(--highlight);
  font-size: 0.6em;
  letter-spacing: 1px;
}

// Custom
.post-date {
  color: var(--secondary-text);
  //margin-bottom: 1rem;
  font-size: 0.8em;
}
.home-date {
  font-family: "Epilogue";
}
.post-list-item a {
  text-decoration: none;
}
.text-bold {
  font-weight: bold;
}
.text-upcase {
  text-transform: uppercase;
}
p code, li code {
  background-color: var(--bg-secondary);
  padding: 0.2rem;
  color: var(--code-text);
  //font-weight: bold;
}
.post-title {
  margin-bottom:-1.5rem;
}

.project-title {
  margin-bottom: 0.8rem;
}

.credits {
  font-size: 0.8em;
  color: var(--text);
  margin: 4em auto -4em auto;
  text-align: center;
  a {
    color: var(--text);
    text-decoration: none;
    font-weight: bold;
  }
  a:hover {
    color: var(--links);
  }
  padding: 1em;
  border: 1px solid;
  border-color: var(--text);
  border-radius: 5px;
  max-width: 28rem;
}
